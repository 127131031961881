import React, { useEffect, useState } from 'react';
import { BadgeSectionTypes, CurrentUser, MenuResponse, OgData, HiddenComponentProps } from 'types';
import { Footer } from 'components/Footer';
import { BaseSubHeader } from 'components/SubHeader';
import { HeaderSearch } from 'components/SearchComponents/HeaderSearch';
import { HeadComponent } from 'components/HeadComponent';
import { AdvertisementOptionalModal } from 'components/AdvertisementOptionalModal';
import { MainHeader } from 'components/MainHeader';
import { SeoHiddenContent } from 'components/SeoHiddenContent';
import { getCommonOgData, getPageDescription } from 'utils/seoFormatters';
// import { MobileAppBanner } from 'components/MobileAppBanner';

import styles from './CustomPageWrapper.module.scss';

export type CustomerPageWrapperProps = {
  badge?: BadgeSectionTypes;
  children?: React.ReactElement;
  filters?: Record<string, string | number | boolean>;
  headTitle?: string;
  hiddenComponentProps?: HiddenComponentProps;
  ldJsonData?: string;
  menuData?: MenuResponse;
  ogData?: OgData[];
  user?: CurrentUser;
  withFooter?: boolean;
  // seo props
  seoPageName?: string;
  seoPageData?: object;
};

const searchOptions = [
  { id: 1, title: 'Items' },
  { id: 2, title: 'Users' },
];

export const CustomerPageWrapper = ({
  badge,
  children,
  filters,
  headTitle = 'SENDY',
  hiddenComponentProps,
  ldJsonData,
  menuData = {} as MenuResponse,
  ogData,
  user,
  withFooter = true,
  seoPageName = 'default',
  seoPageData = {},
  appliedFilters = {},
}: CustomerPageWrapperProps) => {
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpened]);

  const toggleOpened = () => {
    setOpened((isOpened) => !isOpened);
  };

  if (!ogData) {
    ogData = getCommonOgData({
      description: getPageDescription(seoPageName as string, seoPageData),
    });
  }

  return (
    <React.Fragment>
      <HeadComponent headTitle={headTitle} ldJsonData={ldJsonData} ogData={ogData} />
      {ldJsonData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJsonData }}></script>}
      {hiddenComponentProps && <SeoHiddenContent {...hiddenComponentProps} />}
      <MainHeader
        changeOpened={toggleOpened}
        searchItems={<HeaderSearch user={user} headerSearch={true} placeholder="Search gear & apparel" showQueryInUsers />}
        user={user}
        badge={badge}
      />
      <BaseSubHeader
        isOpened={isOpened}
        setIsOpened={setOpened}
        menuData={menuData}
        filters={filters}
        withBadge={badge?.is_enabled}
        appliedFilters={appliedFilters}
      />
      {/*<MobileAppBanner />*/}
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>{children && children}</div>
      </div>
      {withFooter && <Footer />}
      <AdvertisementOptionalModal />
    </React.Fragment>
  );
};
